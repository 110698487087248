.App {
  text-align: center;
}

.main {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;

  @media (min-width: 800px) {
    padding: 24px;
    gap: 48px;
  }
}

.title {
  font-weight: bold;
  font-size: 22px;
  margin: 0;

  @media (min-width: 800px) {
    font-size: 32px;
  }
}

.text-body {
  font-size: 16px;
  margin: 0;
  color: #546e7a;

  @media (min-width: 800px) {
    font-size: 20px;
  }
}

.card {
  width: calc(100% - 32px);
  padding: 16px;

  @media (min-width: 800px) {
    padding: 32px;
    max-width: 650px;
  }
}
