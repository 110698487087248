.header {
  position: sticky;
  padding: 16px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  background-color: #2196f3;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: white;
  max-width: 100%;

  @media (min-width: 800px) {
    flex-wrap: nowrap;
  }
}

.header__logo {
  height: fit-content;
  flex-basis: 100%;
  display: flex;
  justify-content: center;

  @media (min-width: 800px) {
    flex: 1 1 0;
    order: 2;
  }

  svg {
    width: 130px;
    @media (min-width: 800px) {
      width: 260px;
    }
  }
}

.header__lang {
  display: flex;
  flex: 1 1 0;
  @media (min-width: 800px) {
    order: 1;
  }

  .MuiSelect-select {
    padding: none !important;
  }
}

.header__user {
  flex-basis: 50%;
  padding-left: 16px;
  margin-left: auto;
  @media (min-width: 800px) {
    order: 3;
    flex: 1 1 0;
  }
  button {
    color: white;
    font-size: 13px;
    font-weight: normal;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 8px;
    margin-left: auto;

    @media (min-width: 800px) {
      font-size: 18px;
      gap: 16px;
    }
  }

  svg {
    height: 24px;
    width: 24px;
  }
}
